import styled, { css } from 'styled-components'
import { BannerProps } from '@/interfaces/banner'
import { Alignment } from '@/constants'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import SectionWrapper from '@/components/SectionWrapper'

export const Wrapper = styled(SectionWrapper)<{ sectionTheme?: SectionThemeKey }>`
  background-color: ${({ sectionTheme, theme }) => sectionTheme === SectionThemeKey.Dark ? theme.colors.surface01 : theme.colors.white};
`

export const InnerProductSuiteTabsWrapper = styled.div``

export const ProductSuiteWrapper = styled.div<{ alignment?: BannerProps['alignment'] }>`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: grid;
    grid-template-columns: repeat(2, 554px);
    grid-column-gap: 40px;
    grid-auto-flow: dense;
    align-items: center;

    ${({ alignment }) => alignment === Alignment.Right && css`
      > ${InnerProductSuiteTabsWrapper} {
        grid-column: 2;
        grid-row: 1;
      }
    `}
  }
`
